
import MessageSmsRepository from '@/shared/http/repositories/socialProject/message-sms'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import MessageSmsModel from '@/shared/models/messageSms'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import NewRegisterMessageSms from '@/components/layout/NewRegisterMessageSms/NewRegisterMessageSms.vue'

export default {
  name: 'EditarMensagemSms',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegisterMessageSms.name]: NewRegisterMessageSms
  },

  data: () => ({
    placeholderTitle: 'Ex: Faltas',
    placeholderMessage: 'Ex: ABDA: ....',
    title: 'Mensagem SMS',
    MessageSmsModel: new MessageSmsModel(),
    isNew: false
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Mensagem SMS',
            href: this.$router.resolve({ name: 'MessageSms' }).href
          },
          {
            text: 'Editar Mensagem',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
  },

  methods: {
    getById() {
      loading.push()
      MessageSmsRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.MessageSmsModel = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar mensagem', 'ERRO')
        })
    },

    update(data) {
			data.title = data.title.toUpperCase()
      data.message = data.message.toUpperCase()

      loading.push()
      MessageSmsRepository.Update(data)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Mensagem editada com sucesso', 'EDITAR MENSAGEM SMS')
          Promise.resolve()
          return this.$router.replace({ name: 'MessageSms' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar mensagem', 'ERRO')
        })
    }
  }
}
